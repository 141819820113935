export const ENV_CONFIG = {
  FRONTEND_CONFIG: (() =>
    //@ts-ignore - disbaled the lint here
    document.querySelector('meta[name="frontend-variables"]')?.dataset ||
    //@ts-ignore - disbaled the lint here
    document.querySelector('div[name="frontend-variables"]')?.dataset)(),
};

export const getBasePath = () => {
  // todo: remove console.log after testing in dev
  const el = document?.querySelector?.('[data-appbasepath]');
  console.log('getBasePath', el, el?.getAttribute?.('data-appbasepath'))
  if (el) {
    return el.getAttribute('data-appbasepath') || '/rental-tracker';
  }

  return '/rental-tracker';
}

export const checkIsPreviewModeEnabled = () => {
  // todo: remove console.log after testing in dev
  const el = document?.querySelector?.('[data-previewmode]');
  console.log('checkIsPreviewModeEnabled', el, el?.getAttribute?.('data-previewmode'))
  if (el) {
    const value = `${el.getAttribute('data-previewmode')}`;
    console.log('checkIsPreviewModeEnabled value', value.trim().toLowerCase() === 'true')
    return value.trim().toLowerCase() === 'true';
  }

  return false;
}