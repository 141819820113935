import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";

import App from "./components/App";
import ErrorBoundary from "./components/ErrorBoundary";
import {
  LANDING_FORM_PATH,
  RES_CONTRACT_SELECTION_PATH,
  SINGLE_SHIPMENT_STATUS_PATH,
} from "./constants/routeConstants";
import AnalyticsContextProvider from "./context/analyticsContext/AnalyticsContext";
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from "./store";

import "./styles/global.scss";
import { getBasePath } from "./utils/envUtils";

const router = createBrowserRouter([
  {
    path: getBasePath(),
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: LANDING_FORM_PATH,
        async lazy() {
          const { LandingFormWithGrayBackground } = await import("./modules/landing/LandingForm");
          return { Component: LandingFormWithGrayBackground };
        },
      },
      {
        path: RES_CONTRACT_SELECTION_PATH,
        async lazy() {
          const { ContractOrReservationSelectionWithGrayBackground } = await import("./modules/contract/ContractOrReservationSelection");
          return { Component: ContractOrReservationSelectionWithGrayBackground };
        },
      },
      {
        path: SINGLE_SHIPMENT_STATUS_PATH,
        async lazy() {
          const { default: ShipmentStatus } = await import("./modules/shipmentStatus/ShipmentStatus");
          return { Component: ShipmentStatus };
        },
      },
    ],
  },
]);

const RootApp = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AnalyticsContextProvider>
          <RouterProvider router={router} />
        </AnalyticsContextProvider>
      </PersistGate>
    </Provider>
  );
};
const renderDOM = (event) => {
  // check if dom is ready to interact or completely loaded on the screen
  if (
    event.target.readyState === "interactive" ||
    event.target.readyState === "complete"
  ) {
    // find the root element
    const container = document.querySelector("#root");
    if (container) {
      // readystatechange only fire once!
      document.removeEventListener("readystatechange", renderDOM);
      // render react app into the DOM
      const rootContainer = createRoot(document.getElementById("root"));
      rootContainer.render(<RootApp />,);
    }
  }
};

document?.addEventListener("readystatechange", renderDOM);
