import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  fetchAuthToken,
  fetchIncExcPcList,
  selectCurrentPathname,
  selectIsTokenLoading,
  setCurrentPathname,
  setIsPageRefreshed,
  setUserProfile,
} from '../modules/contract/contractSlice';
import appEnv from '../env';
import { hideShimmer } from '../utils/commonUtils';
import { SHIMMER_TIMEOUT } from '../constants/appConstants';
import { appLogger } from '../utils/logger';
import { useAuthoringFieldsRequest } from '../hooks/useAuthoringFieldsRequest';

const App = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const profileLoadTimer = useRef<ReturnType<typeof setTimeout>>();
  const onUnMount = useRef<any>(null);

  const currentPathname = useAppSelector(selectCurrentPathname);
  const isTokenLoading = useAppSelector(selectIsTokenLoading);

  useAuthoringFieldsRequest({ isTokenLoading });

  useEffect(() => {
    dispatch(setIsPageRefreshed(location.pathname === currentPathname));
    dispatch(setCurrentPathname(location.pathname));

    hideShimmer();

    //  *** commenting the code below to show the contract id field as soon as app is loaded and 
    //  ** showing shimmer on zipcode and submit button until user profile is loaded

    // check if current page is Landing/Home page
    // const isHomePage = PROJECT_PATH_NAME.includes(location.pathname) || PROJECT_PATH_NAME_LOCAL.includes(location.pathname);

    // hide shimmer if current page is not home page,
    // for home page shimmer is removed when labels are loaded
    // if (!isHomePage) {
    // hideShimmer();
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const setUserProfileLoaded = (data: any) => {
    appLogger.setSentryScope(
      data?.detail?.type ?? 'guest',
      data?.detail?.cart_token ?? '',
      [
        {
          key: 'applicationContext',
          value: 'DeliveryTracker'
        }
      ]
    );
    dispatch(setUserProfile(data?.detail ?? null));
    clearTimeout(profileLoadTimer.current);
  }

  useEffect(() => {
    dispatch(
      fetchAuthToken({
        client_id: appEnv.REACT_APP_CLIENT_ID,
        client_secret: appEnv.REACT_APP_CLIENT_SECRET,
        scope: "Mulesoft",
      })
    );

    dispatch(fetchIncExcPcList(""));

    profileLoadTimer.current = setTimeout(() => {
      appLogger.logWarning('profile load timeout')
      dispatch(setUserProfile(null));
    }, SHIMMER_TIMEOUT);

    const userProfileUtils = window?.getIsProfileLoaded?.();

    if (userProfileUtils?.isProfileLoaded) {
      setUserProfileLoaded({
        detail: userProfileUtils?.userProfile ?? null
      });
    } else {
      document?.addEventListener("onProfileLoadComplete", setUserProfileLoaded);

      onUnMount.current = () => {
        document?.removeEventListener("onProfileLoadComplete", setUserProfileLoaded);
      }
    }

    const isDevelopment = process.env.NODE_ENV === 'development';
    if (!isDevelopment) {
      appLogger.init();
    }

    return () => {
      onUnMount.current?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* App code */}
      <Outlet />
    </>
  );
};

export default App;
