import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { checkIsPreviewModeEnabled } from "../utils/envUtils";
import { getLandingPageAuthoringFields } from "../services/aemService";
import { selectIsLabelLoaded, setAuthoringLabels } from "../modules/contract/contractSlice";

export interface UseAuthoringFieldsRequestProps {
  isTokenLoading: boolean;
}

export const useAuthoringFieldsRequest = ({
  isTokenLoading
}: UseAuthoringFieldsRequestProps) => {
  const dispatch = useAppDispatch();
  const isLabelsLoaded = useAppSelector(selectIsLabelLoaded);
  const [labelsLoaded, setLabelsLoaded] = useState(isLabelsLoaded);

  useEffect(() => {
    const isInPreviewMode = checkIsPreviewModeEnabled();

    if (!isTokenLoading && (!isLabelsLoaded || isInPreviewMode)) {
      // todo: handler for catch block
      getLandingPageAuthoringFields()
        .then((response) => {
          // store labels in redux for caching
          dispatch(
            setAuthoringLabels(
              response?.data?.trackerDeliveryModelList?.items?.[0]
            )
          )
        })
        .finally(() => {
          // always set this flag to true after the call is completed
          setLabelsLoaded(true)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenLoading, isLabelsLoaded]);

  return { labelsLoaded };
}
