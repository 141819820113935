// location pathname according to route
export const PROJECT_ROOT_PATH = "rental-tracker";
export const PROJECT_PATH_NAME = "/rental-tracker/"
export const PROJECT_PATH_NAME_LOCAL = "/content/tracker/us/en_US.html/"
export const LANDING_FORM_PATH = "";
export const RES_CONTRACT_SELECTION_PATH = "rescontractlist";
export const SINGLE_SHIPMENT_STATUS_PATH = "shipmentstatus";
export const MULTI_SHIPMENT_STATUS_PATH = "multishipmentstatus";

// class names according to pages
export const LOGIN_PAGE_CLASS = 'login-page';
export const SHIPMENT_PAGE_CLASS = 'shipment-status-page';
export const CONTRACT_RES_SELECTION_CLASS = 'contract-selection-page';

export const SBR_US_BRANCH_LOCATION_BASE_URL = '/location'
export const SBR_CA_BRANCH_LOCATION_BASE_URL = '/location/ca'

// list of all application pages
export const appPages = [
  {
    path: SINGLE_SHIPMENT_STATUS_PATH,
    className: SHIPMENT_PAGE_CLASS,
  },
  {
    path: RES_CONTRACT_SELECTION_PATH,
    className: CONTRACT_RES_SELECTION_CLASS,
  }
];