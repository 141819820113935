import instance from "./config/client";
import { FETCH_AEM_GRAPHQL_URL, FETCH_PAGE_FIELDS_URL } from "../constants/apiConstants";
import { appLogger } from "../utils/logger";
import { checkIsPreviewModeEnabled } from "../utils/envUtils";
import axios from "axios";

const triggerLabelsRequest = (pageId: string) => {
  const isPreviewMode = checkIsPreviewModeEnabled();
  if (isPreviewMode) {
    return axios.get(`/${FETCH_AEM_GRAPHQL_URL(pageId)}`);
  }
  return instance.get(
    FETCH_PAGE_FIELDS_URL(pageId),
    {
      headers: {
        companyId: '01',
      },
    }
  );
};

const getLandingPageAuthoringFields = async () => {
  try {
    const { data } = await triggerLabelsRequest('tracker/DTLandingPageQuery');
    return data;
  } catch (e: any) {
    appLogger.logError(
      e?.message ?? 'getLandingPageAuthoringFields API failed',
      false,
      "getLandingPageAuthoringFields",
    );
    throw e;
  }
};

export { getLandingPageAuthoringFields };
